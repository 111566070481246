<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar title="Hỗ trợ trực tuyến" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>
    <div class="iframe_page">
      <iframe width="100%" height="100%"  frameborder="0"  id="iframe_web"   :src="this.$store.getters.getBaseInfo.kefu !==undefined ?this.$store.getters.getBaseInfo.kefu:'https://zalo.me'"  ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    back(){
      return window.history.back();
    }
  },
  created() {

  },
  mounted(){
    /**
     * iframe-宽高自适应显示
     */
    const oIframe = document.getElementById('iframe_web');
    const deviceHeight = document.documentElement.clientHeight;
    oIframe.style.height = (Number(deviceHeight)-65) + 'px'; //数字是页面布局高度差
    const tele = document.querySelector('iframe').getAttribute('src');
    if (tele.indexOf('facebook') != -1 || tele.indexOf('fb.com') != -1 || tele.indexOf('t.me') != -1 || tele.indexOf('zalo') != -1) {
      window.open(tele, '_blank');
      return window.history.back();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
iframe {
  height: calc(100vh - 50px);
  width:100%;
  border:0;
}
</style>
